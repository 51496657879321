export enum FileType {
  TRACKS = "tracks",
  COVERS = "covers",
  CSV = "csv",
}

export const DEFAULT_VALUES = {
  [FileType.TRACKS]: null,
  [FileType.COVERS]: null,
  [FileType.CSV]: null,
};

export enum ErrorCode {
  PARSING = "CSV_PARSING",
  UPLOAD = "CSV_UPLOAD",
}
