import { axiosS3Instance } from "../middleware";
import { AbstractService } from "./abstract.service";
import { UploadFileDto } from "./types";

class AssetsService extends AbstractService {
  constructor() {
    super(axiosS3Instance);
  }

  async uploadFile({ url, file }: UploadFileDto): Promise<void> {
    return await this.axiosInstance.put(url, file, {
      headers: {
        "Content-type": file.type,
      },
    });
  }
}

export const assetsService = new AssetsService();
