import { AxiosInstance } from "axios";
import { serviceAxiosInstance } from "../middleware";

export abstract class AbstractService {
  protected readonly axiosInstance: AxiosInstance;

  protected constructor(axiosInstance?: AxiosInstance) {
    this.axiosInstance = axiosInstance || serviceAxiosInstance;
  }
}
