import { bulkUploadService } from "../../services";
import { assetsService } from "../../services/assets.service";
import { TDrafts, TValidationError } from "../../services/types";

type TUploadFiles = { files?: File[]; isTracks: boolean };

const getUrls = async (files: File[], isTracks: boolean) => {
  const filesNames = files.map((file) => file.name);

  if (isTracks) {
    return await bulkUploadService.getTracksUrls(filesNames);
  }

  return await bulkUploadService.getCoversUrls(filesNames);
};

const uploadFile = async (file: File, url: string) => {
  await assetsService.uploadFile({
    url,
    file,
  });
};

export const uploadFiles = async (
  { isTracks, files }: TUploadFiles,
  incrementStateResult: () => void,
): Promise<string[]> => {
  if (!files) throw new Error("Files is missing");

  const response = await getUrls(files, isTracks);
  const urls = response.data;

  const uploadPromises = files.map(async (file) => {
    try {
      return await uploadFile(file, urls[file.name]);
    } catch (error) {
      console.error(error);
      return await Promise.reject(file.name);
    } finally {
      incrementStateResult();
    }
  });

  const result = await Promise.allSettled(uploadPromises);

  const failedUploads: string[] = [];

  for (const item of result) {
    if (item.status === "rejected") {
      failedUploads.push(item.reason);
    }
  }

  return failedUploads;
};

export const isDraftsWithValidationErrors = (response: TDrafts): response is TValidationError[] => {
  if (!response.length) {
    return false;
  }

  const firstElement = response[0];

  return "constraints" in firstElement && "property" in firstElement;
};
