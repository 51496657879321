import { Button, Paper, Stack } from "@mui/material";
import { FC, Fragment, useCallback, useState } from "react";
import { FileField, FileInput, ImageField, ImageInput, Link, useCreatePath, useNotify } from "react-admin";
import { useFormContext } from "react-hook-form";
import { WithProgressBar } from "../../components";
import { bulkUploadService } from "../../services";
import { TDrafts, TUploadFileValidationError } from "../../services/types";
import { ErrorCode, FileType } from "./constants";
import { UploadResultsDialog } from "./UploadResultsDialog";
import { isDraftsWithValidationErrors, uploadFiles } from "./utils";

import uniqueId from "lodash/uniqueId";

type THandleFilesButtons = {
  onApply: () => void;
  onReset: () => void;
  isDisabled: boolean;
};

type TBulkUploadFormInputs = {
  drafts: TDrafts | null;
  setIsLoading: (isLoading: boolean) => void;
  setDrafts: (drafts: TDrafts) => void;
};

enum UploadResultStatus {
  NotStarted = "not started",
  Started = "started",
  Finished = "finished",
}

type TUploadingState = {
  status: UploadResultStatus;
  failedUploads?: string[];
  filesNumber?: number;
  finishedFilesNumber?: number;
  fileType?: FileType;
};

const calculateProgress = (state: TUploadingState) => {
  const filesNumber = state?.filesNumber || 0;
  const finishedFilesNumber = state?.finishedFilesNumber || 0;

  return !filesNumber ? 0 : Math.round((finishedFilesNumber / filesNumber) * 100);
};

const HandleFilesButtons: FC<THandleFilesButtons> = ({ onApply, onReset, isDisabled }) => {
  return (
    <Stack direction="row" justifyContent="end" spacing="32px" mb="1em" width="100%">
      <Button disabled={isDisabled} variant="contained" onClick={onApply}>
        Upload Files
      </Button>
      <Button disabled={isDisabled} color="error" onClick={onReset}>
        Clear
      </Button>
    </Stack>
  );
};

export const BulkUploadFormInputs: FC<TBulkUploadFormInputs> = ({ setIsLoading, drafts, setDrafts }) => {
  const { watch, resetField, getValues } = useFormContext();
  const createPath = useCreatePath();
  const [uploadingState, setUploadingState] = useState<TUploadingState>({ status: UploadResultStatus.NotStarted });

  const notify = useNotify();
  const handleNotify = (message: string) => {
    notify(message, { type: "error" });
  };

  const handleReset = (fileType: FileType) => {
    resetField(fileType);
  };

  const handleUploadFiles = useCallback(
    async (fileType: FileType) => {
      try {
        const isTracks = fileType === FileType.TRACKS;
        const values = getValues(fileType);

        if (values?.length) {
          setUploadingState({
            status: UploadResultStatus.Started,
            filesNumber: values?.length,
            finishedFilesNumber: 0,
            fileType,
          });

          const result = await uploadFiles({ isTracks, files: values.map(({ rawFile }) => rawFile) }, () => {
            setUploadingState((prev) => ({ ...prev, finishedFilesNumber: prev.finishedFilesNumber + 1 }));
          });

          setUploadingState({ status: UploadResultStatus.Finished, failedUploads: result });

          resetField(fileType);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    },
    [watch(FileType.TRACKS), watch(FileType.COVERS)],
  );

  const handleUploadCsv = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await bulkUploadService.uploadCsv(getValues(FileType.CSV).rawFile);
      setDrafts(data);
      resetField(FileType.CSV);
    } catch (err: any) {
      if (err?.response?.data?.code === ErrorCode.UPLOAD) {
        const { response } = err as TUploadFileValidationError;
        return setDrafts(response.data.details.validationErrors);
      }

      handleNotify(err?.response?.data?.message);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [watch(FileType.CSV)]);

  const handleAlertDialogClose = () => {
    setUploadingState((prev) => ({ ...prev, status: UploadResultStatus.NotStarted }));
  };

  return (
    <WithProgressBar
      text={`${uploadingState.finishedFilesNumber}/${uploadingState.filesNumber} ${uploadingState.fileType}`}
      isLoading={uploadingState?.status === UploadResultStatus.Started}
      progress={calculateProgress(uploadingState)}
    >
      <FileInput accept=".csv" source={FileType.CSV} label="CSV">
        <FileField source="src" title="title" />
      </FileInput>
      <HandleFilesButtons
        isDisabled={!watch(FileType.CSV)}
        onReset={() => handleReset(FileType.CSV)}
        onApply={handleUploadCsv}
      />
      <FileInput multiple accept=".wav,.mp3,.ogg" source={FileType.TRACKS} label="Tracks">
        <FileField source="src" title="title" />
      </FileInput>
      <HandleFilesButtons
        isDisabled={!watch(FileType.TRACKS)}
        onReset={() => handleReset(FileType.TRACKS)}
        onApply={() => handleUploadFiles(FileType.TRACKS)}
      />
      <ImageInput multiple source={FileType.COVERS} label="Album Covers">
        <ImageField source="src" title="title" />
      </ImageInput>
      <HandleFilesButtons
        isDisabled={!watch(FileType.COVERS)}
        onReset={() => handleReset(FileType.COVERS)}
        onApply={() => handleUploadFiles(FileType.COVERS)}
      />
      <UploadResultsDialog
        open={uploadingState?.status === UploadResultStatus.Finished}
        failedUploads={uploadingState?.failedUploads}
        onClose={handleAlertDialogClose}
      />
      {drafts && (
        <Paper variant="outlined" sx={{ width: "100%", mb: "24px", p: "16px" }}>
          <ul>
            {isDraftsWithValidationErrors(drafts)
              ? drafts.map(({ constraints, property }) => (
                  <Fragment key={uniqueId("property_")}>
                    <li>{property}</li>
                    {Object.values(constraints) && (
                      <ul>
                        {Object.values(constraints).map((constraint) => (
                          <li key={uniqueId("constraint_")}>{constraint}</li>
                        ))}
                      </ul>
                    )}
                  </Fragment>
                ))
              : drafts.map(({ tracks, name, _id }) => (
                  <Fragment key={_id}>
                    <li>
                      <Link to={createPath({ resource: "albums", type: "show", id: _id })}>{name}</Link>
                    </li>
                    {tracks && (
                      <ul>
                        {tracks.map((track) => (
                          <li key={track._id}>
                            <Link to={createPath({ resource: "tracks", type: "show", id: track._id })}>
                              {track.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Fragment>
                ))}
          </ul>
        </Paper>
      )}
    </WithProgressBar>
  );
};
