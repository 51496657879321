import { stringify } from "qs";
import { TAlbum } from "../types/data";
import { AbstractService } from "./abstract.service";
import { TDrafts, TUrl } from "./types";

class BulkUpload extends AbstractService {
  constructor(
    private readonly apiUrl = "/albums/bulk-upload",
    private readonly apiUrlFiles = "/albums/bulk-upload/files",
  ) {
    super();
  }

  async downloadTemplate() {
    const { data } = await this.axiosInstance.get(this.apiUrl + `/export`);
    const blob = new Blob([data], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "template.csv";
    link.click();
  }

  async getDrafts() {
    const { data } = await this.axiosInstance.get<TAlbum[]>(this.apiUrl);
    return data;
  }

  async uploadCsv(rawFile: File) {
    const formData = new FormData();
    formData.append("data", rawFile);

    return await this.axiosInstance<TDrafts>(this.apiUrl, {
      method: "POST",
      data: formData,
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }

  async getTracksUrls(filesNames: string[]) {
    return this.axiosInstance.get<TUrl>(
      this.apiUrlFiles + `/tracks?${stringify({ filesNames }, { arrayFormat: "brackets" })}`,
    );
  }

  async getCoversUrls(filesNames: string[]) {
    return this.axiosInstance.get<TUrl>(
      this.apiUrlFiles + `/albums?${stringify({ filesNames }, { arrayFormat: "brackets" })}`,
    );
  }

  async approveDrafts() {
    return this.axiosInstance.patch<TDrafts>(this.apiUrl);
  }

  async declineDrafts() {
    return this.axiosInstance.delete(this.apiUrl);
  }
}

export const bulkUploadService = new BulkUpload();
