import CloseIcon from "@mui/icons-material/Close";
import { Alert, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import uniqueId from "lodash/uniqueId";
import { FC } from "react";

interface TUploadResultsDialog {
  open?: boolean;
  onClose: () => void;
  failedUploads: string[];
}

export const UploadResultsDialog: FC<TUploadResultsDialog> = ({
  open,
  onClose,
  failedUploads,
}: TUploadResultsDialog) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload results</DialogTitle>
      <IconButton
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: "10px 20px 24px 20px" }}>
        {failedUploads?.length ? (
          <Alert severity="warning">
            <b>These files haven't been uploaded due to errors:</b>
            <ul>
              {failedUploads.map((item) => (
                <li key={uniqueId("uploaded-file-name")}>{item}</li>
              ))}
            </ul>
          </Alert>
        ) : (
          <Alert severity="success">All files have been uploaded successfully</Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};
