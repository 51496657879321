import axios, { CreateAxiosDefaults } from "axios";
import { SessionService } from "../../utils";

const backendAxiosConfig: CreateAxiosDefaults<any> = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json",
  },
};

export const axiosInstance = axios.create(backendAxiosConfig);

export const serviceAxiosInstance = axios.create(backendAxiosConfig);

export const axiosS3Instance = axios.create();

const getToken = () => {
  const { accessToken } = SessionService.get();
  return accessToken;
};

const handleRequestFulfilled = (config: any) => {
  const accessToken = getToken();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

const handleServiceResponseRejected = async (error) => {
  if (error.response && error.response.status === 401) {
    try {
      await SessionService.refresh();

      const newToken = getToken();

      error.config.headers.Authorization = `Bearer ${newToken}`;

      return serviceAxiosInstance(error.config);
    } catch (refreshError) {
      throw refreshError;
    }
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(handleRequestFulfilled);

serviceAxiosInstance.interceptors.request.use(handleRequestFulfilled);
serviceAxiosInstance.interceptors.response.use((response) => response, handleServiceResponseRejected);
