import { Backdrop, Box, Typography } from "@mui/material";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FC, PropsWithChildren } from "react";

type TWithProgress = {
  isLoading: boolean;
  progress?: number;
  text?: string;
} & PropsWithChildren;

export const WithProgressBar: FC<TWithProgress> = ({ children, isLoading, text, progress }) => {
  return (
    <>
      {children}
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ maxWidth: "150px", maxHeight: "150px" }}>
          <CircularProgressbarWithChildren
            value={progress}
            styles={{
              path: { stroke: "#fff" },
              trail: { display: "none" },
            }}
            strokeWidth={6}
          >
            <Typography
              variant="body1"
              fontSize="1rem"
              sx={{ fontWeight: "bold", color: "#fff", px: 2, textAlign: "center" }}
            >
              {text}
            </Typography>
          </CircularProgressbarWithChildren>
        </Box>
      </Backdrop>
    </>
  );
};
