import { AbstractService } from "./abstract.service";

class Downloads extends AbstractService {
  constructor(private readonly apiUrl = "/downloads") {
    super();
  }

  reset(id: string) {
    return this.axiosInstance.patch(this.apiUrl + `/reset/${id}`);
  }
}

export const downloadsService = new Downloads();
